import { FetchService } from './fetch-service.js';
import { getTeamInfo } from '../static';

const api = new FetchService({
  baseUrl: `https://api.mysportsfeeds.com/v2.1/pull`,
  headers: {
    Authorization: `Basic ${btoa(
      `${process.env.VUE_APP_MSF_KEY}:${process.env.VUE_APP_MSF_PWD}`
    )}`,
    'Accept-Encoding': 'gzip' // reduce 90% bandwidth from MSF servers
  }
});

function getStatus({ scheduleStatus, playedStatus }) {
  return scheduleStatus === `NORMAL` && playedStatus;
}

const transformers = {
  nfl: {
    weeklyGames(data) {
      const formattedData = [];
      for (let i = 0; i < data.games.length; ++i) {
        formattedData.push({
          ...this.game(data.games[i].schedule),
          updated: data.lastUpdatedOn
        });
      }
      return formattedData;
    },
    game(data) {
      return {
        id: data.id,
        week: data.week,
        status: () => getStatus(data),
        dateTime: data.startTime,
        awayTeam: data.awayTeam.abbreviation,
        homeTeam: data.homeTeam.abbreviation,
        awayTeamId: data.awayTeam.id,
        homeTeamId: data.homeTeam.id,
        scheduleStatus: data.scheduleStatus,
        playedStatus: data.playedStatus
      };
    },
    playerGameLogsArray(data) {
      // the following stats are missing:
      // injuryStatus: data.InjuryStatus,
      // injuryBodyPart: data.InjuryBodyPart,
      // injuryStartDate: data.InjuryStartDate,
      // injuryNotes: data.InjuryNotes,
      const formattedData = [];
      for (let i = 0; i < data.gamelogs.length; ++i) {
        formattedData.push({
          ...this.playerGameLog(data.gamelogs[i]),
          updated: data.lastUpdatedOn
        });
      }
      return formattedData;
    },
    // does not include the id or source id, as they will differ
    playerGameLog(data) {
      return {
        firstName: data.sourceFirstName,
        lastName: data.sourceLastName,
        position: data.sourcePosition,
        rosterSlots: data.rosterSlots,
        team: data.sourceTeam,
        gameId: data.game.id
      };
    },
    injuredPlayerArray(data) {
      const formattedData = [];
      for (let i = 0; i < data.players.length; ++i) {
        formattedData.push({
          ...this.injuredPlayer(data.players[i]),
          updated: data.lastUpdatedOn
        });
      }
      return formattedData;
    },
    injuredPlayer(data) {
      return {
        id: data.id,
        bodyPart: data.currentInjury.description,
        playingProbability: data.currentInjury.playingProbability
      };
    },
    weeklyDFS(data) {
      if (!data.sources) {
        return [];
      }
      const formattedData = [];
      const slates = data.sources[0].slates;
      const playerRefs = data.references.playerReferences;
      // showdown contests have player pairs processed at once,
      // so after incrementing iter by 1, increment again by stride
      let stride = 1;

      for (let i = 0; i < slates.length; ++i) {
        let processPlayers;
        const contestType = slates[i].contests[0].type;
        if (
          contestType.split(' ')[0] === `Showdown` ||
          contestType === `KFC Sunday Night Series`
        ) {
          stride = 2;
          processPlayers = j => {
            const p = slates[i].players[j];
            const pNext = slates[i].players[j + 1];

            // if (!pNext) {
            //   console.log(`pNext is undefined at slate ${i}, player ${j}`);
            //   // return;
            // }

            let pKeyPrefix = p.rosterSlots[0].toLowerCase();
            let pNextKeyPrefix = pNext.rosterSlots[0].toLowerCase();
            let value;

            // KFC showdown uses "COL" instead of "CPT"
            // keep it simple and use "cpt" prefix
            if (pKeyPrefix === `flex`) {
              value = p.salary;
              pNextKeyPrefix = `cpt`;
            } else {
              value = pNext.salary;
              pKeyPrefix = `cpt`;
            }

            const sourceIds = [
              p.sourceId.split('/'),
              pNext.sourceId.split('/')
            ];

            const player = {
              ...this.playerGameLog(p),
              value,
              [`${pKeyPrefix}Id`]: sourceIds[0][1],
              [`${pNextKeyPrefix}Id`]: sourceIds[1][1]
            };

            if (p.player) {
              if (pNext.player && pNext.player.id == p.player.id) {
                player.id = p.player.id;
                player.injury = findPlayerInjury(p.player.id, playerRefs);
              } else {
                // just add player
                // console.log(
                //   `The selected pair of players are not equal:`,
                //   p,
                //   pNext
                // );
              }
            } else {
              // if (pNext.sourceFirstName === p.sourceFirstName)
              player.id = +sourceIds[0][0];
            }

            players.push(player);
          };
        } else {
          processPlayers = j => {
            const p = slates[i].players[j];
            const sourceId = p.sourceId.split('/');
            const player = {
              ...this.playerGameLog(p),
              value: p.salary,
              // second number of `sourceId` is the ID unique to whether the player is "flex" or "cpt"
              uSourceId: sourceId[1]
            };

            if (p.player) {
              player.id = p.player.id;
              player.injury = findPlayerInjury(p.player.id, playerRefs);
            } else {
              player.id = +sourceId[0];
            }
            players.push(player);
          };
        }

        // reduce player, team, and game objects in player array item to a single object
        const players = [];
        for (let j = 0; j < slates[i].players.length; j = j + stride) {
          processPlayers(j);
        }

        // add extra team info for games
        for (let j = 0; j < slates[i].games.length; ++j) {
          const g = data.references.gameReferences.find(
            x => x.id == slates[i].games[j].id
          );

          slates[i].games[j] = {
            id: g.id,
            week: g.week,
            startTime: g.startTime,
            awayTeam: { ...g.awayTeam, ...getTeamInfo(g.awayTeam.id) },
            homeTeam: { ...g.homeTeam, ...getTeamInfo(g.homeTeam.id) }
          };
        }

        formattedData.push({
          ...slates[i],
          players,
          updated: data.lastUpdatedOn
        });
      }

      return formattedData;
    },
    season(s) {
      return {
        slug: s.slug,
        startDate: s.startDate.slice(0, -1),
        endDate: s.endDate.slice(0, -1)
      };
    }
  }
};

const mySportsFeeds = {
  nfl: {
    /**
     * get games by week
     * @param {*} season - ({start year}-{end year}-{'regular' | 'playoff'}) | 'current' | 'latest' | 'upcoming'
     * @param {*} week
     * @param {*} transform
     */
    async weeklyGames(season, week, transform = true) {
      try {
        // const data = await api.get(
        //   `${baseUrl}/nfl/${season}/week/${week}/games.json`
        // );
        const data = await import('../samples/msf-weeklyGames.json').then(
          module => {
            return module.default;
          }
        );

        return transform ? transformers.nfl.weeklyGames(data) : data;
      } catch (e) {
        // console.log(e);
      }
    },
    async weeklyPlayerGameLogs(season, week, transform = true) {
      try {
        // const data = await api.get(
        //   `${baseUrl}/nfl/${season}/week/${week}/player_gamelogs.json`
        // );

        const data = await import(
          '../samples/msf-weeklyPlayerGamelogs.json'
        ).then(module => {
          return module.default;
        });

        return transform ? transformers.nfl.playerGameLogsArray(data) : data;
      } catch (e) {
        // console.log(e);
      }
    },
    async injuredPlayers(transform = true) {
      try {
        // const data = await api.get(`${baseUrl}/nfl/injuries.json`);

        const data = await import('../samples/msf-playerInjuries.json').then(
          module => {
            return module.default;
          }
        );

        return transform ? transformers.nfl.injuredPlayerArray(data) : data;
      } catch (e) {
        // console.log(e);
      }
    },
    async gamesAndLineups(season, week) {
      const [games, players, injuries] = await Promise.all([
        this.weeklyGames(season, week, false),
        this.weeklyPlayerGameLogs(season, week, false),
        this.injuredPlayers(false)
      ]);
      // console.log(games, players, injuries);

      // group by game id
      let groups = players.gamelogs.reduce((r, a) => {
        r[a.game.id] = [
          ...(r[a.game.id] || []),
          {
            ...transformers.nfl.playerGameLog(a),
            injury: (() => {
              const injury = injuries.players.find(p => p.id === a.player.id);
              return injury ? transformers.nfl.injuredPlayer(injury) : null;
            })()
          }
        ];
        return r;
      }, {});
      // console.log('group', groups);

      const gameLineups = [];
      for (let i = 0; i < games.games.length; ++i) {
        gameLineups.push({
          ...transformers.nfl.game(games.games[i].schedule),
          updated: games.lastUpdatedOn,
          lineup: groups[games.games[i].schedule.id]
        });
      }
      // console.log(`lineups`, gameLineups);
      return gameLineups;
    },
    async weeklyDFS(season, week, { transform = true, fantasyProvider }) {
      try {
        const data = await api.get(
          `/nfl/${season}/week/${week}/dfs.json${
            fantasyProvider ? `?dfstype=${fantasyProvider}` : ''
          }`
        );

        // const data = await import('../samples/msf-weeklyDFS.json').then(
        //   module => {
        //     return module.default;
        //   }
        // );

        return transform ? transformers.nfl.weeklyDFS(data) : data;
      } catch (e) {
        // console.log(e);
      }
    },
    // * if no player is found, an array of DFS sources are returned with no further nested data
    async seasonalDFS(season, { player, fantasyProvider }) {
      try {
        const queryParams = [];
        player && queryParams.push(`?player=${player}`);
        fantasyProvider && queryParams.push(`dfstype=${fantasyProvider}`);
        // return api.get(
        //   `${baseUrl}/nfl/${season}/dfs.json${
        //     player ? `?player=${player}` : ''
        //   }`
        // );
        return api.get(`/nfl/${season}/dfs.json${queryParams.join('&')}`);

        // return await import('../samples/msf-seasonalDFS.json').then(module => {
        //   return module.default;
        // });
      } catch (e) {
        // console.log(e);
      }
    },
    // TODO: build a proper date when `current` is given
    async stats(
      { season, week, query } = {
        season: `current`,
        week: `current`,
        query: null
      }
    ) {
      try {
        let q = [];
        for (const key in query) {
          q.push(`${key}=${query[key]}`);
        }

        // console.log(
        //   `request: `,
        //   `${baseUrl}/nfl/${season}${
        //     week ? `/week/${week}` : ''
        //   }/player_gamelogs.json${q.length ? `?${q.join('&')}` : ''}`
        // );

        // return await import('../samples/msf-statfilters.json').then((mod) => {
        //   return mod.default.gamelogs;
        // });

        const data = await api.get(
          `/nfl/${season}${week ? `/week/${week}` : ''}/player_gamelogs.json${
            q.length ? `?${q.join('&')}` : ''
          }`
        );
        return data;
        //#region local
        // return await import('../samples/msf-weeklyPlayerGamelogs.json').then(
        //   (module) => {
        //     const filteredPlayers = (({ gamelogs }) => {
        //       // split playerString into 3 possible parts
        //       const playerNames = playerString.split('-');
        //       if (playerNames.length === 3) {
        //         // filter by id
        //         return gamelogs.filter((p) => p.player.id == playerNames[2]);
        //       } else {
        //         // filter by last name
        //         if (playerNames.length == 2) {
        //           return gamelogs.filter(
        //             (p) =>
        //               // if found && playerString has 2 parts, check first name and filter if necessary
        //               p.player.lastName === playerNames[1] &&
        //               p.player.firstName === playerNames[0]
        //           );
        //         }
        //         // don't check for first name if not supplied
        //         return gamelogs.filter(
        //           (p) => p.player.lastName === playerNames[0]
        //         );
        //       }
        //     })(module.default)
        //       // transform results to match a Player structure
        //       .map((p) => {
        //         return { ...p.player, stats: p.stats };
        //       });

        //     return filteredPlayers[0];
        //   }
        // );
        //#endregion
      } catch (e) {
        // console.log(e);
      }
    },
    /**
     * @param {Date|undefined} date - if undefined, will be the current date
     * @return {Object|undefined} 1 or both seasons, undefined for no season or playoff with no preceeding regular season
     */
    async getSeason(date = undefined) {
      const url = '/nfl/current_season.json';
      const s = (
        await api.get(`${url}${date ? `?date=${formatDateString(date)}` : ''}`)
      ).seasons;
      // no season
      if (!s.length) return;

      const a = transformers.nfl.season(s[s.length - 1]);
      const today = new Date();
      const start = new Date(a.startDate);
      const end = new Date(a.endDate);
      const isCurrent = today >= start && today <= end;

      if (s[s.length - 1].seasonInterval === 'POSTSEASON') {
        // get previous regular season
        start.setDate(start.getDate() - 7);
        const s2 = (await api.get(`${url}?date=${formatDateString(start)}`))
          .seasons;

        if (!s2.length) {
          // shouldn't have a playoff season with no regular season
          // ? throw error
          return;
        }

        return makeSeason([transformers.nfl.season(s2[s2.length - 1]), a], 1);
      }

      // undefined date gives current season, has no playoff season yet
      if (!date) return makeSeason([a], 0);

      const b = [a];
      // if is a previous year's regular season, a playoff season will exist
      if (!isCurrent) {
        end.setDate(end.getDate() + 7);
        const s3 = (await api.get(`${url}?date=${formatDateString(end)}`))
          .seasons;

        s3.length && b.push(transformers.nfl.season(s3[s3.length - 1]));
      }

      return makeSeason(b, 0);
    },
    transform: transformers.nfl
  }
};

const makeSeason = (intervals, current = undefined) => ({ intervals, current });

function findPlayerInjury(playerId, playerRefs) {
  for (let i = 0; i < playerRefs.length; ++i) {
    if (playerRefs[i].id == playerId) {
      return playerRefs[i].currentInjury;
    }
  }
}

function formatDateString(date) {
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year }
  ] = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).formatToParts(date);
  return `${year}${month}${day}`;
}

export default mySportsFeeds;

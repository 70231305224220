import Vue from 'vue';
import Router from 'vue-router';
import store from './store.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/slates'
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/Login.vue'),
      meta: { public: true, onlyWhenLoggedOut: true }
    },
    {
      path: '/slates',
      name: 'slates',
      component: () =>
        import(/* webpackChunkName: "slates" */ './views/Slates.vue')
    },
    {
      path: '/slates/:id',
      component: () =>
        import(/* webpackChunkName: "slate" */ './views/Slate.vue'),
      beforeEnter: async (to, from, next) => {
        store.state.selectedSlate ? next() : next('/');
      },
      children: [
        {
          path: '',
          name: 'slate',
          component: () =>
            import(/* webpackChunkName: "gen" */ './views/LineupGen.vue')
        },
        {
          path: 'history',
          name: 'history',
          component: () =>
            import(/* webpackChunkName: "history" */ './views/GenHistory.vue')
        },
        {
          path: 'players/:playerId',
          name: 'playerStats',
          component: () =>
            import(
              /* webpackChunkName: "playerStats" */ './views/PlayerStats.vue'
            )
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
      meta: { public: true }
    }
  ]
});

// Routes private by default. Redirect accordingly
router.beforeEach((to, from, next) => {
  // auth guard
  const { loggedIn } = store.state.user;

  if (!to.meta.public && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && to.meta.onlyWhenLoggedOut) {
    return next('/');
  }

  next();
});

export default router;

import Vue from 'vue';
import Vuex from 'vuex';
import router from './router.js';
import { api } from './apis/fetch-service.js';
// import sportsdata from './apis/sd-api.js';
import msf from './apis/msf-api.js';
import { diffWeeks } from './util/util.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    slates: [],
    selectedSlate: null,
    games: [],
    players: [],
    gameLineup: null,
    userLineup: {
      QB: null,
      RB: [],
      WR: [],
      TE: null,
      FLEX: null,
      DST: null
    },
    lineupTeam: null,
    lineupGame: null,
    firstRoute: null, // the route that the user opened the app with
    currentSport: 'nfl',
    currentDataSrc: msf,
    fantasyProvider: `DraftKings`, // or 'FanDuel'
    regularSeason: {
      // TODO: replace `2019-regular` string with a function that returns the current season
      // * to get a full season, will need to combine '[year]-regular' + ('latest' | '[year+1]-playoffs')
      slug: `2020-regular`,
      start: `2020-09-10`,
      end: `2021-01-03`
    },
    playoffSeason: {
      slug: `2020-playoffs`,
      start: `2021-01-09`,
      end: `2021-02-07`
    },
    selectedDate: new Date(),
    selectedSeason: null,
    seasonWeek: 1, // 17 is regular season end, 18-22 are playoffs
    historyCount: window.history.length, // used to restrict page history navigation to only app pages
    user: {
      loggedIn: false,
      username: null
    }
  },
  getters: {
    dataHandler: state => state.currentDataSrc[state.currentSport],
    gameDate: state => gameId => {
      for (const s of state.slates) {
        const g = s.games.find(g => g.id === gameId);

        if (g) return g.startTime;
      }
    },
    season: state => {
      const s = state.selectedSeason;
      if (!s) return;

      const season = s.intervals[s.current].slug.split('-');
      return `${season[0]} ${
        season[1].charAt(0).toUpperCase() + season[1].slice(1)
      }`;
    }
  },
  mutations: {
    setSlates(state, slates) {
      state.slates = slates;
    },
    setGames(state, games) {
      state.games = games;
    },
    setGameLineup(state, lineup) {
      state.gameLineup = lineup;
    },
    setPlayers(state, players) {
      state.players = players;
    },
    pushToLineup(state, playerId) {
      state.userLineup.push(playerId);
    },
    setFirstRoute(state, rt) {
      state.firstRoute = rt;
    },
    setWeek(state, week) {
      state.seasonWeek = week;
    },
    setSeason(state, season) {
      state.selectedSeason = season;
    },
    selectSlate(state, index) {
      state.selectedSlate = index >= 0 ? state.slates[index] : null;
    },
    setDate(state, date) {
      state.selectedDate = date;
    },
    setLoggedIn(state, data) {
      state.user.loggedIn = data.loggedIn;
      state.user.username = data.username;
    }
  },
  actions: {
    async getGames(
      { commit, getters },
      { season, week } = { season: `current`, week: `current` }
    ) {
      const games = await getters.dataHandler.gamesAndLineups(season, week);

      commit(`setGames`, games);
    },
    async getPlayers({ commit, dispatch }, { gameId } = {}) {
      try {
        if (gameId) {
          if (!this.state.games.length) await dispatch(`getGames`);

          const game = this.state.games.find(g => g.id == gameId);
          return game && game.lineup;
        } else {
          const players = await import('./samples/players.js').then(
            module => module.default
          );

          commit(`setPlayers`, players);
          return this.state.players;
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async getPlayer({ dispatch }, id) {
      try {
        if (this.state.games.length) {
          for (let i = 0; i < this.state.games.length; ++i) {
            const player = this.state.games[i].lineup.find(p => p.id == id);
            if (player) {
              return player;
            }
          }
        } else {
          const players = this.state.players.length
            ? this.state.players
            : await dispatch(`getPlayers`);

          return players.find(p => p.id == id);
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async getFantasyGames(
      { commit, state },
      {
        season = state.selectedSeason,
        week = state.seasonWeek,
        fromCache = true
      } = {}
    ) {
      if ((fromCache && state.slates.length) || !season) return;

      // TODO: check for invalid season, such as `undefined`
      const data = await msf.nfl.weeklyDFS(
        season.intervals[season.current].slug,
        week,
        {
          fantasyProvider: state.fantasyProvider
        }
      );

      commit(`setSlates`, data);
    },
    async getFantasyPlayers(
      { commit, dispatch, state },
      { gameId, slate } = {}
    ) {
      try {
        if (slate) {
          const s = state.slates[slate];

          // if (!s.games.some(g => g.id === gameId)) {
          //   return;
          // }

          commit(
            `setPlayers`,
            s.players /* .filter(p => {
              console.log(p.gameId === gameId, p.gameId, gameId);
              return p.gameId === gameId;
            }) */
          );
        } else {
          for (let i = 0; i < state.slates.length; ++i) {
            const s = state.slates[i];
            if (!s.games.some(g => g.id === gameId)) {
              continue;
            }

            commit(
              `setPlayers`,
              s.players.filter(p => p.gameId === gameId)
            );
            break;
          }
        }

        return this.state.players;
      } catch (e) {
        // console.log(e);
      }
    },
    /**
     * @typedef {Object.<Function, any>} RequiredArguments
     * @param {Function} commit - Vuex commit mutation callback
     * @param {Object} state - Vuex state
     */
    /**
     * @typedef {Object.<string, any>} FantasyPlayerOptions
     * @param {string} playerString - <lastName> | <firstName>-<lastName> | <firstName>-<lastName>-<playerId>
     * @param {number} playerId - if playerString is undefined, use playerId to lookup player from state
     * @param {string} season - the season to fetch data for
     */
    /**
     * @param {RequiredArguments} context - Vuex context
     * @param {FantasyPlayerOptions} options
     */
    async getFantasyPlayer(
      // eslint-disable-next-line no-unused-vars
      { state },
      { playerString, playerId, season } = {
        season: state.selectedSeason,
        week: state.seasonWeek
      }
    ) {
      try {
        // TODO: Optimize to reduce duplicated work between getting stats and getting fantasy points
        if (!playerId) {
          if (!playerString) {
            return;
          }

          // get stats
          // const playerStats = await msf.nfl.stats(playerString);
          // console.log(`playerStats: `, playerStats);
          // return playerStats;
          // TODO: get a player by string and then grab the player id
          return;
        }

        const player = state.players.find(p => p.id === playerId);
        // console.log(`player id: `, player.id, playerId);
        if (!player) {
          // TODO: fetch player

          // playerString = `${player.firstName}-${player.lastName}-${playerId}`.toLowerCase();
          // const playerStats = await msf.nfl.stats(playerString);

          // return playerStats ? { ...player, stats: playerStats.stats } : player;
          return;
        }

        if (!playerString) {
          playerString = `${player.firstName}-${player.lastName}`.toLowerCase();
        }
        // console.log(`playerString: `, playerString);
        // get stats
        // Remove ID as it causes no data to return from MSF
        // TODO: Remove when MSF fixes this
        const sParts = playerString.split(`-`);
        if (sParts.length == 3) playerString = `${sParts[0]}-${sParts[1]}`;

        const playerStats = msf.nfl.stats({
          // TODO: check for invalid season, such as `undefined`
          season: season.slug,
          week,
          player: playerString
        });
        const newPlayer = { ...player, stats: playerStats };
        // console.log(`playerStats: `, newPlayer);
        return newPlayer;
      } catch (e) {
        // console.log(e);
      }
    },
    /** @returns {string} message indicating success or failure. */
    addToLineup({ commit, state }, player) {
      // rule: must contain no more than 1 QB, 2 RB, 3 WR, 1 TE, 1 FLEX, 1 DST
      // console.log(player.team, player.gameId);

      // * rule: all players must be of same game, same team
      // if (state.lineupTeam && player.team !== state.lineupTeam) {
      //   const msg = `Player not in same team as the lineup.`;
      //   console.log(msg);
      //   return msg;
      // } else {
      //   state.lineupTeam = player.team;
      // }

      // if (state.lineupGame && player.gameId !== state.lineupGame) {
      //   const msg = `Player is listed for a different game than the game in the lineup.`;
      //   console.log(msg);
      //   return msg;
      // } else {
      //   state.lineupGame = player.gameId;
      // }

      let maxLength;
      if (player.position === `RB`) {
        maxLength = 2;
      } else if (player.position === `WR`) {
        maxLength = 3;
      } else {
        // all others positions are objects
        if (state.userLineup[player.position]) {
          // console.log(
          //   `not array & ${player.position} slot is filled`,
          //   state.userLineup[player.position]
          // );
          return `Slot already filled.`;
        }

        Vue.set(state.userLineup, player.position, player);
        // ? incase Vue.set() isn't working
        // state.userLineup = { ...state.userLineup, [player.position]: player };
        // console.log(`not array: `, state.userLineup);
        return `Player Added.`;
      }
      // console.log(`is array`);
      // add player to either `RB` or `WR`
      const slot = state.userLineup[player.position];

      if (slot.length >= maxLength) {
        // console.log(`all slots filled`);
        return `All slots have been filled for this position.`;
      }

      if (slot.find(p => p.id === player.id)) {
        // console.log(`player already in lineup`);
        return `This player is already in your lineup.`;
      }

      slot.push(player);
      // console.log(
      //   `${player.position} should contain ${player.firstName} ${player.lastName} `,
      //   state.userLineup
      // );

      return `Player Added`;
    },
    /**
     * Get player's fantasy points accumulated each week for a given season
     * @param {object} VuexContext
     * @param {object} params
     * * season (Required) - which season to get fantasy points for
     * * player (Required) - who to get fantasy points for
     * @param {string} params.season
     * @param {string} params.player
     * @returns {Promise<Player[]>} a promise the resolves to an array of player objects.
     * * If a week is missing from the results, it is likely the player team's BYE week
     */
    async getFantasyPts({ state }, params) {
      // TODO: provide a hint to the user when a week is missing from the results
      try {
        // Remove ID as it causes no data to return from MSF
        // TODO: Remove when MSF fixes this
        if (params.player) {
          const sParts = params.player.split(`-`);
          if (sParts.length == 3) params.player = `${sParts[0]}-${sParts[1]}`;
        }

        const s = state.selectedSeason;
        const { sources } = await msf.nfl.seasonalDFS(
          // TODO: check for invalid season, such as `undefined`
          params.season || (s && s.intervals[s.current].slug),
          {
            player: params.player,
            fantasyProvider: state.fantasyProvider
          }
        );

        // * reduce seasonalDFS data to only the fantasy points for each week
        const data = [];
        // iterator to avoid duplicate entries for same week/game
        let forWeek = null;

        for (let i = 0; i < sources.length; ++i) {
          // if player wasn't found, slates array won't exist
          if (!sources[i].slates) {
            continue;
          }

          for (let j = 0; j < sources[i].slates.length; ++j) {
            const slate = sources[i].slates[j];

            if (forWeek >= slate.forWeek) {
              continue;
            }

            const p = slate.players[0];
            if (p) {
              // points for current week retrieved, move on to the next
              forWeek = slate.forWeek;

              data.push({
                fantasyPoints: p.fantasyPoints
                  ? p.fantasyPoints.toFixed(2)
                  : `Did not play`,
                salary: p.salary,
                week: forWeek
              });
            }
          }
        }

        // return newest to oldest
        return data.reverse();
      } catch (e) {
        // console.error(e);
      }
    },
    async changeDate({ commit, dispatch, state }, date = new Date()) {
      const a = JSON.parse(localStorage.getItem(`season`));
      let season;
      if (!a) {
        season = await msf.nfl.getSeason(date);
      } else {
        season =
          date >= new Date(a.intervals[0].startDate) &&
          date <= new Date(a.intervals[a.intervals.length - 1].endDate)
            ? a
            : await msf.nfl.getSeason(date);
      }

      if (!season) return;

      localStorage.setItem(`season`, JSON.stringify(season));
      commit(`setSeason`, season);
      // 2. set season week
      const seasonStart = new Date(season.intervals[0].startDate);
      // make new week occur at start of the day, not later in the day
      seasonStart.setHours(0);
      const dayNum = date.getDay();
      const startDay = seasonStart.getDay();
      // copy to show the future date's slates while keeping the current date
      const _date = new Date(date);
      // show next week's games after monday (last game day of prev week)
      // before a full week has occured (determined by season start day)
      dayNum > 1 &&
        dayNum < startDay &&
        _date.setDate(_date.getDate() + (startDay - dayNum));

      // +1 to count from 1
      commit(`setWeek`, diffWeeks(seasonStart, _date) + 1);
      commit(`setDate`, date);
    },
    async getSlate({ commit, dispatch, state }, id) {
      const i = state.slates.findIndex(s => s.identifier == id);
      if (i != -1) {
        commit(`selectSlate`, i);
        return i;
      }

      await dispatch(`getFantasyGames`);
      const index = state.slates.findIndex(s => s.identifier == id);

      index != -1 && commit(`selectSlate`, index);

      return index;
    },
    async saveLineupGenFilters({ state }, run) {
      run.username = state.user.username;
      return api.post(`/lineupgen`, run);
    },
    async getLineupGenFilters(ctx, contestDate) {
      return api.get(
        `/lineupgen/${contestDate.split('T')[0].replaceAll('-', '')}`
      );
    },
    async login({ commit }, creds) {
      const user = {
        username: (
          await api.post(`/users/${creds.username}/login`, {
            password: creds.password
          })
        ).username,
        loggedIn: true
      };

      localStorage.setItem(`user`, JSON.stringify(user));
      commit(`setLoggedIn`, user);
      router.push(router.history.current.query.redirect || '/');
    },
    async autoLogin({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        return router.replace({
          path: '/login',
          query: { redirect: router.history.current.fullPath }
        });
      }

      commit(`setLoggedIn`, user);
      api.get(`/users/${user.username}`).catch(e => {
        commit(`setLoggedIn`, { loggedIn: false });
        router.replace({
          path: '/login',
          query: { redirect: router.history.current.fullPath }
        });
      });
    },
    logout({ commit }) {
      commit(`setLoggedIn`, { loggedIn: false });
      localStorage.removeItem('loggedIn');
      router.replace(`/login`);
    }
  }
});

const teamLogos = [
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/u9fltoslqdsyao8cpm0k',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/d8m7hzpsbrl6pnqht8op',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ervfzgrqdpnc7lh5gqwq',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ra0poq2ivwyahbaq86d2',
  'https://static.www.nfl.com/image/private/f_auto/league/ieid8hoygzdlmzo0tnf6',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ocvxwnapdvwevupe4tpr',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/gppfvr7n8gljgjaqux2x',
  'https://static.www.nfl.com/image/private/f_auto/league/ayvwcmluj2ohkdlbiegi',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/teguylrnqqmfcwxvcmmz',
  'https://static.www.nfl.com/image/private/f_auto/league/grhjkahghjkk17v43hdx',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/t6mhdmgizi6qhndh8b9p',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/puhrqgj71gobgdkdo6uq',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/dxibuyxbk0b9ua5ih9hn',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/gcytzwpjdzbpwnwxincg',
  'https://static.www.nfl.com/image/private/f_auto/league/v8uqiualryypwqgvwcih',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/haxrowry8puwbrixjdmc',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ucsdijmddsqcj1i9tddd',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/giphcy6ie9mxbnldntsf',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/okxpteoliyayufypqalq',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/grxy59mqoflnksp2kocc',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/t0p7m5cjdjy18rnzzqbx',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/bpx88i8nw4nnabuq0oob',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ketwqeuschqzjsllbid5',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/ujshjqvmnxce8m4obmvs',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/qycbib6ivrm9dqaexryk',
  'https://static.www.nfl.com/image/private/f_auto/league/gzcojbzcyjgubgyb6xf2',
  'https://static.www.nfl.com/image/private/f_auto/league/dhfidtn8jrumakbogeu4',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/lits6p8ycthy9to70bnt',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/moyfxx3dq5pio4aiftnc',
  'https://static.www.nfl.com/image/private/f_auto/league/ekijosiae96gektbo4iw',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/xujg9t3t4u5nmjgr54wx',
  'https://res.cloudinary.com/nflleague/image/private/f_auto/league/pln44vuzugjgipyidsre'
];

const teamSites = [
  'https://www.azcardinals.com/',
  'https://www.atlantafalcons.com/',
  'https://www.panthers.com/',
  'https://www.chicagobears.com/',
  'https://www.dallascowboys.com/',
  'https://www.detroitlions.com/',
  'https://www.packers.com/',
  'https://www.therams.com/',
  'https://www.vikings.com/',
  'https://www.neworleanssaints.com/',
  'https://www.giants.com/',
  'https://www.philadelphiaeagles.com/',
  'https://www.49ers.com/',
  'https://www.seahawks.com/',
  'https://www.buccaneers.com/',
  'https://www.redskins.com/',
  'https://www.baltimoreravens.com/',
  'https://www.buffalobills.com/',
  'https://www.bengals.com/',
  'https://www.clevelandbrowns.com/',
  'https://www.denverbroncos.com/',
  'https://www.houstontexans.com/',
  'https://www.colts.com/',
  'https://www.chiefs.com/',
  'https://www.jaguars.com/',
  'https://www.raiders.com/',
  'https://www.chargers.com/',
  'https://www.miamidolphins.com/',
  'https://www.patriots.com/',
  'https://www.newyorkjets.com/',
  'https://www.steelers.com/',
  'https://www.titansonline.com/'
];

const teamBgColors = [
  '#98233f',
  '#a71930',
  '#0185ca',
  '#0b1629',
  '#012244',
  '#0076b4',
  '#203732',
  '#003595',
  '#512683',
  '#d4bc8d',
  '#0b2265',
  '#004c55',
  '#ab0001',
  '#012244',
  '#a71930',
  '#5a1315',
  '#241774',
  '#00348d',
  '#fb4f13',
  '#ff5a29',
  '#012244',
  '#032130',
  '#012d60',
  '#e11836',
  '#000',
  '#000',
  '#0080c7',
  '#008e97',
  '#012244',
  '#155841',
  '#000',
  '#012244'
];

const teamIndexes = {
  76: 0,
  68: 1,
  69: 2,
  60: 3,
  52: 4,
  61: 5,
  62: 6,
  77: 7,
  63: 8,
  70: 9,
  53: 10,
  54: 11,
  78: 12,
  79: 13,
  71: 14,
  55: 15,
  56: 16,
  48: 17,
  57: 18,
  58: 19,
  72: 20,
  64: 21,
  65: 22,
  73: 23,
  66: 24,
  74: 25,
  75: 26,
  49: 27,
  50: 28,
  51: 29,
  59: 30,
  67: 31
};

export const getTeamInfo = id => ({
  logo: teamLogos[teamIndexes[id]],
  site: teamSites[teamIndexes[id]],
  color: teamBgColors[teamIndexes[id]]
});

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.config.productionTip = false;

store.dispatch(`autoLogin`);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  }
}).$mount('#app');

export const getClickedListElement = e => {
  const item = e.target.closest(`li`);
  if (!item) {
    return;
  }

  const { children } = item.parentElement;
  for (let i = 0; i < children.length; ++i) {
    if (children[i] === item) {
      return i;
    }
  }
};

/** returns true if object is not empty, false otherwise */
export function some(o) {
  for (const key in o) {
    if (key) {
      return true;
    }
  }
  return false;
}
export const diffWeeks = (date1, date2) =>
  Math.floor((date2 - date1) / 1000 / 60 / 60 / 24 / 7);

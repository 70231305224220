import { API } from '../config.js';

export class FetchService {
  constructor({ headers = {}, baseUrl = API, options = {} } = {}) {
    this._headers = new Headers({
      'Content-Type': 'application/json',
      ...headers
    });
    this.baseUrl = baseUrl;
    this._options = options;
  }

  get headers() {
    return this._headers;
  }

  /** Should not include a body */
  get(url, options = {}) {
    return this.request(url, { ...options, method: 'GET' });
  }

  post(url, body) {
    return this.request(url, { method: 'POST', body });
  }

  patch(url, body) {
    return this.request(url, { method: 'PATCH', body });
  }

  put(url, body) {
    return this.request(url, { method: 'PUT', body });
  }

  delete(url) {
    return this.request(url, { method: 'DELETE' });
  }

  request(url, options = {}) {
    if (options.body) {
      options.body = JSON.stringify(options.body);
    }

    return fetch(`${this.baseUrl}${url}`, {
      method: 'GET',
      mode: 'cors',
      headers: this._headers,
      ...this._options,
      ...options
    }).then(res => this.status(res));
  }

  async status(response) {
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        ...((await response.json()) || response.statusText)
      });
    }

    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1
      ? response.json()
      : true;
  }

  postReturnHeaders(url, body = undefined) {
    return fetch(`${this.baseUrl}${url}`, {
      method: `POST`,
      mode: `cors`,
      headers: this._headers,
      ...this._options,
      ...(body ? { body: JSON.stringify(body) } : {})
    }).then(async res => ({
      status: res.status,
      headers: res.headers,
      body: await api.status(res)
    }));
  }
}

export const api = new FetchService({ options: { credentials: 'include' } });
